export default {
  pageHeroV1: {
    container: {
      height: '50vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      marginTop: ['6rem', '', '', '7rem'],
      h1: {
        fontSize: ['2.5rem', '', '3.5rem'],
        marginBottom: 1,
        padding: '2rem',
        border: 'solid 3px',
        borderColor: 'primary',
        backgroundColor: '#64001fb0'
      },
      h2: {
        marginBottom: 4
      },
      button: {
        variant: 'buttons.multiLocation'
      }
    }
  }
}
