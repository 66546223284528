export default {
  heading: 'Rakkas, serif',
  body: 'Lato, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Playfair Display, serif',
  display2: 'Playfair Display, serif',
  googleFonts: ['Rakkas', 'Lato', 'Playfair Display']
  // customFamilies: ['', ''],
  // customUrls: [
  //   'https://gonation.biz/fonts/trend-sans-w00-four/trend-sans-w00-four.css',
  // ],
}
