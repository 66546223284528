export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {},
    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: 'solid 2px',
      borderColor: 'primary'
    },
    '.logo': {
      img: {
        maxHeight: '90px',
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: '50px',
        filter: 'unset'
      }
    },

    '.navMenu': {
      backgroundColor: 'black',
      minWidth: 'unset',
      padding: ['2rem 1rem', '', '', '2rem 1rem 1rem', '2rem 2rem 1rem'],
      alignItems: ['center', '', '', 'flex-start']
    },
    '.navMenuOpen': {
      backgroundColor: 'black',
      color: 'white'
    },

    '.navMenuLogo': {
      img: {
        maxHeight: '100px'
      },
      marginBottom: '1.5rem'
    },

    '.navItem': {
      margin: '1rem',
      borderLeft: 'solid 3px',
      borderColor: 'primary',
      paddingLeft: '2rem',
      color: 'white'
    },
    '.socialLink': {
      svg: {
        path: {
          fill: 'white'
        }
      }
    }
  },

  footer: {
    backgroundColor: 'black',
    maxWidth: 'unset',

    borderTop: 'solid 2px',
    borderColor: 'primary',
    padding: ['2rem 2rem 5rem', '', '2rem 4rem'],
    '.logo': {
      maxHeight: '60px'
    },
    '.ctasContainer': {
      display: 'none'
    },
    '.multiButtonContainer': {
      margin: '0.5rem',
      a: {
        variant: 'buttons.primary',
        margin: ['0.5rem', '0.5rem'],
        padding: '0.5rem',
        borderRadius: '0px'
      }
    },
    '.column .contactDetails-container': {
      margin: '0rem',
      '.text': {
        fontSize: '1rem'
      },
      svg: {
        marginRight: '0.5rem'
      }
    },
    '.column .socialContainer': {
      a: {
        margin: ['0.5rem', '', '', '0.5rem 0rem']
      }
    },
    '.gonationLogo': {
      width: 'auto'
    }
  },

  ctaWidget: {},

  // ? ======================
  // ? =====  Reusables  ====
  // ? ======================

  title: {
    fontSize: ['1.75rem', '2rem', '2.5rem'],
    fontFamily: 'heading'
  },
  subtitle: {
    fontSize: ['1.1rem', '1.2rem', '1.3rem'],
    borderBottom: 'solid 1px',
    borderTop: 'solid 1px',
    borderColor: 'primary',
    padding: '0.5rem 0rem',
    opacity: '0.7',
    fontFamily: 'body',
    color: 'primary'
  },

  sideBySide1: {
    background: 'black',
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background:
          'linear-gradient(90deg,rgb(0 0 0 / 0%),rgb(0 0 0 / 0%) 0%,rgb(0 0 0 / 0%) 50%,rgb(0 0 0 / 10%) 75%,rgb(0 0 0))',
        right: '-2px'
      },
      img: {
        height: ['', '', '75vh']
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: ['', '', '2'],
      width: ['', '', '55%'],
      '::before': {
        left: '-2px',
        background:
          'linear-gradient(-90deg,rgb(0 0 0 / 0%),rgb(0 0 0 / 0%) 0%,rgb(0 0 0 / 0%) 50%,rgb(0 0 0 / 10%) 75%,rgb(0 0 0))'
      },
      img: {
        height: ['', '', '75vh']
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      margin: '0rem auto',
      width: ['90%', '', 'auto', ''],
      height: ['auto', '', '', ''],
      maxWidth: '767px',
      // borderRadius: '500px',
      backgroundColor: '#000000ba',
      border: 'solid 1px',
      borderColor: 'primary',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.5rem', '1.75rem', '2rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['1.75rem', '2rem', '2.5rem', '2.75rem'],
        color: 'white',
        backgroundColor: 'black',
        padding: '0.5rem'
      }
    }
  },

  homepageHeroShout: {
    position: 'absolute',
    left: ['0rem', '', '', '0.5rem'],
    bottom: ['0rem', '', '', '0.5rem'],

    '.shoutWidget': {
      border: 'solid 2px',
      borderColor: 'primary',
      maxHeight: '150px',
      background: '#000000ba',
      '.shoutImage': {
        border: 'none'
      },
      '.content': {},
      '.title': {
        color: 'primary',
        textTransform: 'uppercase'
      },
      '.text': {
        fontSize: '1rem'
      },
      '.date': {
        color: 'primary'
      }
    }
  },

  homepageShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },

  homepageLogos: {
    width: '100%',
    margin: '0rem 0rem 0rem',
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#202020',
    borderTop: '2px solid',
    borderColor: 'primary',
    '::before': {
      variant: 'customVariants.title',
      content: "'Showing At Tavern On 7'",
      marginBottom: '1rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.contentBoxes': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    '.box': {
      margin: '0.5rem',
      img: {
        height: ['55px', '70px'],
        width: 'auto'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {},

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuSectionTitle': {
      borderTop: '2px solid',
      borderBottom: '2px solid',
      borderColor: 'primary',
      margin: '2rem 0rem'
    },
    '.menuSectionDescription': {
      marginBottom: '2rem'
    },
    '.menuItemContentContainer': {
      order: ['2', '', '', 'inherit'],
      margin: '0rem'
    },
    '.menuItemName': {
      fontFamily: 'heading',
      fontSize: ['1.25rem', '1.5rem'],
      textTransform: 'uppercase',
      color: 'primary',
      marginBottom: '0.5rem'
    },

    '.menuItemDescription': {
      opacity: '0.8'
    },
    '.dropdownContainer': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.lazyload-wrapper': {
      width: '100%'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    backgroundColor: '#1c1c1c'
  },

  locationMap: {
    h3: {
      borderBottom: '1px solid',
      borderColor: 'primary',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    }
  }
}
